export const STATUS_REJECTED = 'rejected';
export const STATUS_FULFILLED = 'fulfilled';

export const isRejected = promise =>
    promise?.status === STATUS_REJECTED && promise?.reason instanceof Error;

export const getPromiseAllSettledObject = async promisesMap => {
    const entries = Object.entries(promisesMap);

    // eslint-disable-next-line no-unused-vars
    const result = await Promise.allSettled(entries.map(([_, promise]) => promise));

    return Object.fromEntries(entries.map(([key], index) => [key, result[index]]));
};
