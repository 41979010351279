<template>
    <component :is="tag" v-bind="tagProps" v-if="componentsList.length > 0" class="pbi">
        <template v-for="({ name, props, slots, id }, index) in componentsList">
            <component :is="name" :key="index" v-bind="propsWithSlots(props, slots, id)" />
        </template>
    </component>
</template>

<script>
import { hydrateWhenVisible } from '@assets/vue-lazy-hydration/LazyHydrate';
import { load } from '@modules/page-builder/helpers/component';
import fetchProducts from '@modules/page-builder/helpers/fetchProducts';
import { createNamespacedHelpers } from 'vuex';
import { VALID_INTERPRETER_ROOT_TAGS } from '@modules/page-builder/page-builder.config';
import { capitalizeFirstLetter } from '@assets/string-utility';

const { mapGetters: mapConfigGetters } = createNamespacedHelpers('config');

export default {
    name: 'PageBuilderInterpreter',

    props: {
        componentsJson: {
            type: String,
            required: true,
        },

        tag: {
            type: [String, Object],
            default: 'div',
            validator: value => {
                if (typeof value === 'string') {
                    return VALID_INTERPRETER_ROOT_TAGS.includes(value);
                }

                return true;
            },
        },

        tagProps: {
            type: Object,
            default: () => ({}),
        },
    },

    data() {
        return {
            products: {},
            componentsList: [],
            componentsToLoad: [],
        };
    },

    async fetch() {
        await this.prepareBuilder();
    },

    computed: {
        ...mapConfigGetters(['locale', 'currency']),
    },

    watch: {
        componentsJson: {
            immediate: true,
            async handler() {
                await this.prepareBuilder();
            },
        },
    },

    methods: {
        getProductsToFetch(componentsList) {
            const productsToFetch = [];

            componentsList.forEach(({ fetch = {} }) => {
                Object.values(fetch).forEach(index => {
                    if (!productsToFetch.includes(index)) {
                        productsToFetch.push(index);
                    }
                });
            });

            return productsToFetch;
        },

        propsWithSlots(props = {}, slots = {}, id = '') {
            const slotsProps = Object.entries(slots).reduce((acc, slot) => {
                const [slotName, slotValue] = slot;

                return {
                    ...acc,
                    [`slot${capitalizeFirstLetter(slotName)}`]: JSON.stringify(slotValue),
                };
            }, {});

            return {
                ...props,
                internalId: id,
                ...slotsProps,
            };
        },

        async prepareBuilder() {
            let componentsList = [];

            try {
                const componentsParsed = JSON.parse(this.componentsJson);

                if (Array.isArray(componentsParsed)) {
                    componentsList = componentsParsed;
                }
            } catch {
                return;
            }

            componentsList.forEach(({ name }) => {
                if (
                    typeof this.$options.components[name] !== 'undefined' ||
                    this.componentsToLoad.find(component => component === name)
                ) {
                    return;
                }

                this.componentsToLoad.push(name);
            });

            const productsToFetch = this.getProductsToFetch(componentsList);

            if (productsToFetch.length) {
                this.products = await fetchProducts(
                    this,
                    productsToFetch,
                    this.locale,
                    this.currency
                );
            }

            componentsList.reduce((acc, comp) => {
                const { fetch = {} } = comp;

                Object.keys(fetch).forEach(propName => {
                    const productIndex = fetch[propName];

                    comp.props[propName] = this.products[productIndex];
                });

                return {
                    ...acc,
                    ...comp,
                };
            }, {});

            this.registerComponents();

            this.componentsList = componentsList;
        },

        registerComponents() {
            this.componentsToLoad.forEach(name => {
                this.$options.components[name] = hydrateWhenVisible(() => load(name), {
                    observerOptions: { rootMargin: '100px' },
                });
            });
        },
    },
};
</script>

<style lang="scss" scoped>
.pbi {
    @apply w-ui-percent-100;
}
</style>
